.mat-mdc-list-base .mdc-list-item {
  font-size: 1rem;
  .mat-mdc-list-text {
    height: 100%;
    place-content: center;
  }
  a {
    height: 100%;
  }
}

// Metadata boxes
.info-box {
  table {
    width: 100%;
    .mat-icon {
      opacity: 0.6;
    }
    td,
    th {
      padding: 4px 0;
      font-weight: normal;
      &:first-child {
        padding-left: 0.9rem;
        width: 2.8rem;
      }
      &:not(:has(.mat-icon)) {
        padding-bottom: 10px;
      }
    }
    th {
      text-align: left;
      color: rgba(0, 0, 0, 0.54);
      white-space: nowrap;
      font-size: 0.9rem;
      // padding-left: 3rem;
    }
    td:last-child {
      text-align: right;
      padding-right: 2rem;
    }
    &[dense] {
      td,
      th {
        padding-bottom: 0;
      }
    }
  }
}

lib-color-picker {
  .mat-mdc-form-field .mat-mdc-form-field-infix {
    .input-group {
      border: 1px solid var(--mutedText);
      border-radius: 4px;
      flex-direction: row-reverse;
      .mat-mdc-input-element {
        border: 0;
        &[type='color'] {
          padding: 0;
          min-height: 2.1rem;
        }
      }
    }
  }
}
