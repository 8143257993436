@use '@angular/material' as mat;

lib-table {
  flex: 1;
  border-radius: 8px;
  border: 1px solid var(--muted);
  overflow: hidden;
  @include mat.elevation(2);
  background-color: var(--panelHead);
}

.mat-mdc-table {
  tr {
    &.mat-mdc-row,
    &.mat-mdc-footer-row,
    &.mat-mdc-header-row {
      height: 36px;
      td,
      th {
        &.right .table-cell-wrapper span {
          text-align: right;
        }
        &.right,
        &.number {
          > * {
            place-content: flex-end;
            place-content: end;
          }
        }
      }
      th.right,
      th.number {
        > * {
          flex-direction: row-reverse;
        }
      }
      td.right,
      th.mat-sort-header-disabled.right {
        padding-right: 1rem;
        &:last-of-type {
          padding-right: calc(1rem + 24px);
        }
      }
    }
    &.mat-mdc-header-row {
      th {
        font-weight: bold;
        background-color: var(--panelBack);
        box-shadow:
          0px 12px 7px -9px rgb(255 255 255 / 50%) inset,
          0px -5px 8px -9px rgb(0 0 0 / 50%) inset;
        border-color: #0000001f;
        color: #0000008a;
      }
    }
    &.mat-mdc-footer-row td.number > * {
      display: flex;
    }

    // Support stretched links. This will stretch across the entire table
    &.mat-mdc-row {
      position: relative;
      &.active td {
        background-color: var(--background);
      }
      &.selected td {
        user-select: none;
        background-color: var(--selected);
      }
      .row-link {
        &:has(a:hover) td {
          background-color: var(--background);
        }
        a {
          &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            z-index: 1;
            pointer-events: auto;
          }
        }
      }
    }
    .number .table-cell-wrapper {
      place-content: center;
    }
  }
  .mat-mdc-cell.mat-mdc-icon-button {
    border-radius: 0;
    min-width: 0;
    padding: inherit;
    height: inherit;
  }
  .mat-mdc-icon-button {
    padding: 5px;
    --mdc-icon-button-state-layer-size: 35px;
    --mdc-icon-button-icon-size: 24px;
  }
  tfoot td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-top: 1px solid var(--muted);
    background-color: var(--panelHead);
  }
}
