form {
  gap: 0.7rem;
}
.mat-mdc-form-field {
  width: 100%;
  &.mat-form-field-disabled label {
    color: var(--muted);
  }
  &:not(:has(.mat-mdc-form-field-hint)) {
    .mat-mdc-form-field-focus-overlay,
    .mdc-line-ripple,
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }
  .mat-mdc-form-field-focus-overlay {
    background-color: transparent;
  }
  .mdc-text-field {
    padding-bottom: 0;
    padding-right: 0;
    background-color: transparent;
    overflow: visible;
    .mdc-line-ripple::after,
    .mdc-line-ripple::before {
      border-width: 0;
    }
    .mat-mdc-form-field-infix {
      display: flex;
      place-items: center;
      padding: 0;
      border-top: 0;
      padding: 0;
      min-height: 0;
      .mdc-floating-label {
        position: static;
        width: auto;
        height: auto;
        padding-top: 0;
        overflow: inherit;
        transform: none;
        width: auto;
        min-width: 6rem;
      }
      .mat-mdc-input-element,
      .mat-mdc-select {
        margin-top: 0;
        border: 1px solid var(--mutedText);
        border-radius: 4px;
        padding: 6px 12px;
        background-color: var(--panelHead);
        &:focus-within:not(.ng-invalid) {
          box-shadow: 0 0 4px var(--mutedText);
        }
        &:is(.ng-invalid) {
          border-color: var(--error);
          box-shadow: 0 0 4px var(--error);
        }
        &:is(.ng-dirty:not(.ng-invalid):not(.no-mark)) {
          background-color: var(--dirty);
        }
        &[disabled] {
          background-color: var(--disabled);
          border-color: var(--muted);
        }
      }
      .mat-mdc-select {
        .mat-mdc-select-arrow-wrapper {
          transform: none;
        }
      }
    }
  }
  .mat-mdc-form-field-hint-wrapper {
    text-align: right;
    display: block;
    padding: 0;
  }
  .mat-mdc-form-field-icon-suffix {
    position: absolute;
    right: 0px;
    &:not(:has(lib-loader)) {
      right: 7px;
      top: 5px;
    }
    .mdc-icon-button {
      width: 24px;
      height: 24px;
      padding: 0;
    }
  }
  .mat-mdc-input-element {
    box-sizing: inherit;
  }
  .input-group {
    flex: 1;
    &:focus-within:not(.ng-invalid) {
      box-shadow: 0 0 4px var(--mutedText);
      .mat-mdc-input-element {
        box-shadow: none;
      }
    }
  }
}
.mat-mdc-form-field .mat-mdc-form-field-infix .mat-mdc-input-element.search {
  transition: all 0.2s linear;
  &:not(:focus-within):not(.hasValue) {
    width: 2.6rem;
    border-color: rgba(0, 0, 0, 0.12);
    background-color: transparent;
  }
}

/**
 * Legacy MDC styles for components in @logic-suite/shared
 */
.mat-form-field {
  width: 100%;
  .mat-form-field-wrapper {
    padding-left: 16px;
    .mat-form-field-infix {
      flex-direction: row-reverse;
      place-items: center;
    }
    .mat-form-field-label-wrapper {
      position: static;
      min-width: 6rem;
      width: auto;
      label {
        position: static;
      }
    }
    .mat-form-field-underline {
      display: none;
    }
    .input-group {
      background-color: var(--panelHead);
      border: 1px solid var(--mutedText);
      border-radius: 4px;
      padding: 2px 12px;
      &:focus-within {
        box-shadow: 0 0 4px var(--mutedText);
      }
    }
  }
}

.mat-mdc-checkbox,
.mat-checkbox {
  &[labelposition='before'] {
    padding-left: 16px;
    label {
      min-width: 5.4rem;
      color: rgba(0, 0, 0, 0.6);
    }
  }
}
.mat-checkbox-checked {
  &.mat-accent .mat-checkbox-background {
    background-color: #0032ff;
  }
  // .mat-checkbox-checkmark-path {
  //   stroke: currentColor;
  // }
}

body .mat-mdc-option {
  min-height: 28px;
}
