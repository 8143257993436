*,
*::after,
*::before {
  box-sizing: border-box;
}
body {
  background-color: var(--background);
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: var(--baseFont);
}
a {
  &:hover {
    text-decoration: underline;
  }
  text-decoration: none;
  text-underline-position: under;
  text-underline-offset: 3px;
  color: var(--link);
}
h1 {
  font:
    700 2rem / 1.35 Cantarell,
    sans-serif;
}
// .mat-mdc-list-text a {
//   text-underline-offset: 0;
// }

.page {
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
  padding: 0 1rem;
}

.function {
  display: flex;
  flex-direction: column;
  height: 100%;
  .mat-toolbar {
    background: transparent;
    height: 3rem;
  }
  > header {
    position: sticky;
    height: 7rem;
    display: flex;
    place-items: flex-end;
    border-bottom: 1px solid var(--muted);
    background-color: var(--panelHead);
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    top: 0rem;
    z-index: 110;
    flex: 0 0 7rem;
    .breadcrumb {
      position: relative;
      top: -0.5rem;
      left: 0.7rem;
      a {
        display: inline-flex;
        place-items: center;
        span {
          margin-left: 0.5rem;
        }
      }
    }
    .heading {
      display: flex;
      place-items: center;
      > .mat-icon {
        margin-right: 1rem;
        font-size: 3rem;
        width: 3rem;
        height: 3rem;
        overflow: visible;
      }
      h1 {
        flex: 1;
        margin: 0;
      }
    }
  }
  > main {
    flex: 1;
    background-color: var(--background);
    display: flex;
    flex-direction: column;
    position: relative;
    > lib-loader {
      position: absolute;
      inset: 0;
      display: flex;
      place-content: center;
      place-items: center;
      z-index: 20;
    }
  }
  .mat-mdc-paginator {
    background: transparent;
    .mdc-notched-outline {
      display: none;
    }
    .mat-mdc-paginator-page-size-select {
      width: 5rem;
    }
  }
}

.hint {
  font-size: 0.8rem;
  font-style: italic;
  opacity: 0.7;
  &.error {
    color: var(--error);
  }
}

.mat-mdc-button-base {
  .mdc-button__label {
    display: flex;
    place-items: center;
  }
  &.mat-mdc-icon-button {
    display: flex;
    place-content: center;
    place-items: center;
    .mat-mdc-button-touch-target {
      width: var(--mdc-icon-button-state-layer-size);
      height: var(--mdc-icon-button-state-layer-size);
    }
  }
}

.invisible-tabs > .mat-mdc-tab-header {
  display: none;
}

.mat-mdc-autocomplete-panel .mat-mdc-option + span {
  font-weight: 100;
  font-size: 0.8rem;
  display: block;
  text-align: center;
  font-style: italic;
  color: var(--mutedText);
}

.mdc-list-item__primary-text p {
  margin: 0;
  display: flex;
  place-items: center;
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #f44336;
  --mat-outlined-button-ripple-color: rgba(244, 67, 54, 0.1);
}
html .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
  --mat-icon-button-state-layer-color: #f44336;
  --mat-icon-button-ripple-color: rgba(244, 67, 54, 0.1);
}
