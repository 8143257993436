@use '@angular/material' as mat;

@import './variables';

@import url('https://fonts.googleapis.com/css2?family=Cantarell:wght@400;700&family=Roboto:wght@300;400;500&family=Fredoka+One&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@include mat.elevation-classes();
@include mat.app-background();

// This file was generated by running 'ng generate @angular/material:m3-theme'.
// Proceed with caution if making changes to this file.
$light-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      use-system-variables: true,
    system-variables-prefix: sys,
    ),
    typography: (
      use-system-variables: true,
    system-variables-prefix: sys,
      brand-family: $baseFont,
    ),
  )
);

html {
  @include mat.elevation-classes();
  @include mat.app-background();
  @include mat.all-component-themes($light-theme);
  @include mat.system-level-typography($light-theme);
}

// prettier-ignore
:root {
  --baseFont: #{$baseFont};
  --mat-autocomplete-background-color: white;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-container-shape: 4px;
  --mat-badge-background-color: var(--panelBack);
  --mat-badge-container-overlap-offset: -12px;
  --mat-badge-container-overlap-offset: -20px -28px;
  --mat-badge-container-padding: 2px 8px;
  --mat-badge-container-shape: 4px;
  --mat-badge-container-size: 16px;
  --mat-badge-disabled-state-background-color: color-mix(in srgb, var(--panelBack) 38%, transparent);
  --mat-badge-disabled-state-text-color: var(--primary);
  --mat-badge-small-size-text-size: 0;
  --mat-badge-text-color: var(--primary);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(0, 49, 62, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(0, 49, 62, 0.3);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(0, 49, 62, 0.2);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(0, 49, 62, 0.4);
  --mat-datepicker-calendar-date-selected-state-background-color: #00313e;
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-toggle-active-state-icon-color: #00313e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-filled-button-container-shape: 4px;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-state-layer-color: black;
  --mat-list-active-indicator-shape: 0;
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-selected-state-label-text-color: #00313e;
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-state-layer-color: black;
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-focused-arrow-color: rgba(0, 49, 62, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-select-panel-background-color: white;
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-track-outline-width: 1px;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-with-icon-handle-size: 20px;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-state-layer-color: black;
  --mdc-circular-progress-active-indicator-color: var(--primary);
  --mdc-dialog-container-color: white;
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-label-text-color: black;
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, .87);
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: .12;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, .87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, .38);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: .04;
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, .38);
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, .87);
  --mdc-list-list-item-label-text-line-height: 1rem;
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, .38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, .38);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, .54);
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: .0178571429em;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, .38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, .38);
  --mdc-outlined-button-container-height: 36px;
  --mdc-outlined-button-container-shape: 4px;
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-plain-tooltip-container-color: var(--panelBack);
  --mdc-plain-tooltip-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-snackbar-container-color: var(--primary);
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-supporting-text-color: var(--background);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-focus-handle-color: #0013ff;
  --mdc-switch-selected-focus-state-layer-color: #002dff;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-focus-track-color: #4d70ff;
  --mdc-switch-selected-handle-color: #002dff;
  --mdc-switch-selected-hover-handle-color: #0013ff;
  --mdc-switch-selected-hover-state-layer-color: #002dff;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-hover-track-color: #4d70ff;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-selected-pressed-handle-color: #0013ff;
  --mdc-switch-selected-pressed-state-layer-color: #002dff;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-selected-pressed-track-color: #4d70ff;
  --mdc-switch-selected-track-color: #4d70ff;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: black;
  --sys-body-large-font: var(--baseFont);
  --sys-body-medium-font: var(--baseFont);
  --sys-body-small-font: var(--baseFont);
  --sys-display-large-font: var(--baseFont);
  --sys-display-medium-font: var(--baseFont);
  --sys-display-small-font: var(--baseFont);
  --sys-headline-large-font: var(--baseFont);
  --sys-headline-medium-font: var(--baseFont);
  --sys-headline-small-font: var(--baseFont);
  --sys-label-large-font: var(--baseFont);
  --sys-label-medium-font: var(--baseFont);
  --sys-label-small-font: var(--baseFont);
  --sys-title-large-font: var(--baseFont);
  --sys-title-medium-font: var(--baseFont);
  --sys-title-small-font: var(--baseFont);
}

.mdc-tooltip__surface {
  box-shadow: var(--mdc-plain-tooltip-container-elevation-shadow);
}

body .mat-badge-medium .mat-badge-content {
  width: auto;
}
