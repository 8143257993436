@use 'sass:map';
@import './variables';

:root {
  // color-scheme: light dark;
  // Default theme
  --mdc-filled-text-field-container-color: transparent;
  @each $name, $value in $theme-light {
    --#{$name}: #{$value};
  }
}
[data-theme='dark'] {
  @each $name, $value in $theme-dark {
    --#{$name}: #{$value};
  }
}

.mat-mdc-button-base.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: white;
}

// Legacy support for components in @logic-suite/shared
.mat-cell {
  border-color: #0000001f;
}
.mat-table tr.mat-header-row th {
  font-weight: 700;
}
.mat-checkbox-frame {
  border-color: #0000008a;
}
